<script>
import Layout from '@/router/layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import PersonalDataTab from '@/components/pages/profile/personal-data-tab';

export default {
  page: {
    title: 'Profile',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  params: ['one'],
  components: {
    Layout,
    PageHeader,
    PersonalDataTab,
  },
  data() {
    return {
      tabIndex: 0,
      title: this.$t('Profile.Title'),
      tabIndexMap: {
        profile: 0,
        personal: 0,
        settings: 1,
        verification: 2,
      },
      pageByIndex: {
        0: 'profile',
        1: 'settings',
        2: 'verification',
      },
    };
  },
  metaInfo() {
    return {
      title: this.$t('Profile.Title'),
    };
  },
  watch: {
    '$route.name'() {
      this.tabIndex = this.tabIndexMap[this.$route.name];
    },
  },
  methods: {
    onTabsInput(value) {
      this.$router.push({ name: this.pageByIndex[value] });
    },
  },
  created() {
    this.tabIndex = this.tabIndexMap[this.$route.name];
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="checkout-tabs">
      <b-tabs
        v-model="tabIndex"
        pills
        vertical
        nav-class="p-0"
        nav-wrapper-class="col-12 col-xl-2 col-sm-3"
        @activate-tab="onTabsInput"
      >
        <b-tab lazy>
          <template v-slot:title>
            <i class="bx bx-user d-block check-nav-icon mt-sm-4 mb-sm-2"></i>
            <p class="fw-bold mb-0 mb-sm-4">{{ $t('Profile.PersonalData.Title') }}</p>
          </template>

          <personal-data-tab lazy />
        </b-tab>

        <b-tab lazy>
          <template v-slot:title>
            <i class="bx bx-cog d-block check-nav-icon mt-sm-4 mb-sm-2"></i>
            <p class="fw-bold mb-0 mb-sm-4">{{ $t('Profile.Settings.Title') }}</p>
          </template>

          <router-view></router-view>
        </b-tab>

        <b-tab title-item-class="mb-2" lazy>
          <template v-slot:title>
            <i class="bx bx-badge-check d-block check-nav-icon mt-sm-4 mb-sm-2"></i>
            <p class="fw-bold mb-0 mb-sm-4">{{ $t('Profile.Verification.Title') }}</p>
          </template>

          <router-view></router-view>
        </b-tab>
      </b-tabs>
    </div>
  </Layout>
</template>

<style scoped lang="scss">
::v-deep(.checkout-tabs button.nav-link) {
  width: 100%;
  color: var(--bs-dark);

  &.active {
    color: var(--bs-nav-pills-link-active-color);
  }
}

::v-deep(.tab-content) {
  width: 100%;
}

@media (max-width: 576px) {
  ::v-deep .checkout-tabs > div {
    flex-direction: column;
  }

  ::v-deep .nav-pills {
    width: 100%;
    display: flex;
    flex-direction: row !important;
  }

  ::v-deep .nav-link {
    padding: 10px;
  }
}
</style>
