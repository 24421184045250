<script>
import ProfileFooter from '@/components/pages/profile/profile-footer';
import Datepicker from 'vue3-datepicker';
import { validationMixin } from '@/mixins/form/validation';
import { ErrorMessage, Field, Form } from 'vee-validate';
import Multiselect from '@vueform/multiselect';

export default {
  name: 'personal-data-tab',
  mixins: [validationMixin],
  components: {
    ProfileFooter,
    Datepicker,
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  data() {
    return {
      loading: true,
      form: {},
      countries: [],
    };
  },
  methods: {
    loadProfile() {
      this.loading = true;

      this.axios
        .get('/profile')
        .then(({ data }) => {
          data = this.formatResponse(data);
          this.$refs.form.setValues(data);
          this.form = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submit(values, actions) {
      this.loading = true;

      if (values.birthday_at instanceof Date) {
        values.birthday_at = this.formatDate(values.birthday_at);
      }

      this.axios
        .put(`/profile`, values)
        .then(({ data }) => {
          data = this.formatResponse(data);
          this.$refs.form.setValues(data);
          this.form = data;

          this.$notify({
            type: 'success',
            text: this.$t('Profile.PersonalData.SuccessfullyUpdated'),
          });
        })
        .catch(({ response }) => {
          this.handleResponseErrors(response, { values, actions });
        })
        .finally(() => (this.loading = false));
    },
    resetForm() {
      this.loadProfile();
      this.$refs.form.resetForm();
    },
    formatDate(value) {
      if (!value) return value;

      return this.$moment(value).format('DD.MM.YYYY');
    },
    formatResponse(data) {
      if (data.birthday_at) {
        data.birthday_at = new Date(this.$moment(data.birthday_at));
      }

      if (data.country) {
        data.country_id = data.country.id;
      }

      return data;
    },
    loadCountries() {
      return this.$store.dispatch('collection/countries').then((data) => {
        this.countries = data.map((country) => ({
          value: country.id,
          text: country.name,
        }));
      });
    },
  },
  mounted() {
    this.loadCountries();
    this.loadProfile();
  },
};
</script>

<template>
  <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
    <Form @submit="submit" ref="form" v-slot="{ handleSubmit }">
      <b-card-text>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-8">
                <h4 class="card-title mb-4">
                  {{ $t('Profile.PersonalData.Title') }}
                </h4>

                <div class="row">
                  <div class="col-md-6">
                    <Field
                      name="last_name"
                      rules="required|max:255"
                      :label="$t('Profile.PersonalData.LastName')"
                      v-slot="{ errors, field }"
                    >
                      <b-form-group class="mb-3 text-muted" :label="$t('Profile.PersonalData.LastName') + '*'">
                        <b-form-input
                          v-model="form.last_name"
                          type="text"
                          :placeholder="$t('Profile.PersonalData.LastName')"
                          :class="{ 'is-invalid': errors.length }"
                          :disabled="$store.getters['auth/user'].disable_name_edit"
                          v-bind="field"
                          v-b-tooltip.hover
                          :title="$store.getters['auth/user'].disable_name_edit ? $t('Profile.PersonalData.EditSupportNotice') : null"
                        />

                        <ErrorMessage name="last_name" class="invalid-feedback" />
                      </b-form-group>
                    </Field>

                    <Field
                      name="first_name"
                      rules="required|max:255"
                      :label="$t('Profile.PersonalData.FirstName')"
                      v-slot="{ errors, field }"
                    >
                      <b-form-group class="mb-3 text-muted" :label="$t('Profile.PersonalData.FirstName') + '*'">
                        <b-form-input
                          v-model="form.first_name"
                          type="text"
                          :placeholder="$t('Profile.PersonalData.FirstName')"
                          :class="{ 'is-invalid': errors.length }"
                          :disabled="$store.getters['auth/user'].disable_name_edit"
                          v-bind="field"
                          v-b-tooltip.hover
                          :title="$store.getters['auth/user'].disable_name_edit ? $t('Profile.PersonalData.EditSupportNotice') : null"
                        />

                        <ErrorMessage name="first_name" class="invalid-feedback" />
                      </b-form-group>
                    </Field>

                    <Field
                      name="middle_name"
                      rules="min:3|max:255"
                      :label="$t('Profile.PersonalData.MiddleName')"
                      v-slot="{ errors, field }"
                    >
                      <b-form-group class="mb-3 text-muted" :label="$t('Profile.PersonalData.MiddleName')">
                        <b-form-input
                          v-model="form.middle_name"
                          type="text"
                          :placeholder="$t('Profile.PersonalData.MiddleName')"
                          :class="{ 'is-invalid': errors.length }"
                          :disabled="$store.getters['auth/user'].disable_name_edit"
                          v-bind="field"
                          v-b-tooltip.hover
                          :title="$store.getters['auth/user'].disable_name_edit ? $t('Profile.PersonalData.EditSupportNotice') : null"
                        />

                        <ErrorMessage name="middle_name" class="invalid-feedback" />
                      </b-form-group>
                    </Field>

                    <Field
                      name="phone"
                      rules="required|phone"
                      :label="$t('Profile.PersonalData.Phone')"
                      v-slot="{ errors, field }"
                    >
                      <b-form-group class="mb-3 text-muted" :label="$t('Profile.PersonalData.Phone')">
                        <b-form-input
                          v-model="form.phone"
                          :disabled="!!form.phone"
                          type="tel"
                          placeholder="+XXXXXXXXXXXX"
                          :class="{ 'is-invalid': errors.length }"
                          v-bind="field"
                        />

                        <ErrorMessage name="phone" class="invalid-feedback" />
                      </b-form-group>
                    </Field>

                    <b-form-group class="mb-3 text-muted" label="Email">
                      <b-form-input
                        disabled
                        v-model="$store.getters['auth/user'].email"
                        type="email"
                        placeholder="Email"
                      />
                    </b-form-group>

                    <Field
                      name="birthday_at"
                      rules="date:DD.MM.YYYY"
                      :label="$t('Profile.PersonalData.BirthdayAt')"
                      v-slot="{ errors, handleChange, handleBlur }"
                    >
                      <b-form-group class="mb-3 text-muted" :label="$t('Profile.PersonalData.BirthdayAt')">
                        <b-input-group>
                          <datepicker
                            v-model="form.birthday_at"
                            confirm
                            class="form-control"
                            placeholder="01.01.1900"
                            input-format="dd.MM.yyyy"
                            :class="{ 'is-invalid': errors.length }"
                            ref="datepicker"
                            @update:modelValue="
                              handleChange(formatDate($event));
                              handleBlur;
                            "
                          />
                          <b-input-group-append is-text :class="{ 'is-invalid': errors.length }">
                            <i class="bx bx-calendar font-size-14" @click="$refs.datepicker.$refs.inputRef.focus()"></i>
                          </b-input-group-append>
                        </b-input-group>

                        <ErrorMessage name="birthday_at" class="invalid-feedback d-block" />
                      </b-form-group>
                    </Field>
                  </div>

                  <div class="col-md-6">
                    <Field
                      name="citizenship"
                      rules="min:1|max:255"
                      :label="$t('Profile.PersonalData.Citizenship')"
                      v-slot="{ errors, field }"
                    >
                      <b-form-group class="mb-3 text-muted" :label="$t('Profile.PersonalData.Citizenship')">
                        <b-form-input
                          v-model="form.citizenship"
                          type="text"
                          :placeholder="$t('Profile.PersonalData.Citizenship')"
                          :class="{ 'is-invalid': errors.length }"
                          v-bind="field"
                        />

                        <ErrorMessage name="citizenship" class="invalid-feedback" />
                      </b-form-group>
                    </Field>

                    <Field
                      name="country_id"
                      rules="required"
                      :label="$t('Profile.PersonalData.Country')"
                      v-slot="{ errors, field }"
                    >
                      <b-form-group class="mb-3 text-muted" :label="$t('Profile.PersonalData.Country') + '*'">
                        <Multiselect
                          v-model="form.country_id"
                          :options="countries"
                          :label="'text'"
                          :rtl="$i18n.locale === 'ar'"
                          :class="{ 'is-invalid': errors.length }"
                          searchable
                          v-bind="field"
                        />

                        <ErrorMessage name="country_id" class="invalid-feedback" />
                      </b-form-group>
                    </Field>

                    <Field
                      name="region"
                      rules="required|min:1|max:100"
                      :label="$t('Profile.PersonalData.Region')"
                      v-slot="{ errors, field }"
                    >
                      <b-form-group class="mb-3 text-muted" :label="$t('Profile.PersonalData.Region') + '*'">
                        <b-form-input
                          v-model="form.region"
                          type="text"
                          :placeholder="$t('Profile.PersonalData.Region')"
                          :class="{ 'is-invalid': errors.length }"
                          v-bind="field"
                        />

                        <ErrorMessage name="region" class="invalid-feedback" />
                      </b-form-group>
                    </Field>

                    <div class="row">
                      <div class="col-sm-8 col-lg-7">
                        <Field
                          name="city"
                          rules="required|min:1|max:100"
                          :label="$t('Profile.PersonalData.City')"
                          v-slot="{ errors, field }"
                        >
                          <b-form-group class="mb-3 text-muted" :label="$t('Profile.PersonalData.City') + '*'">
                            <b-form-input
                              v-model="form.city"
                              type="text"
                              :placeholder="$t('Profile.PersonalData.City')"
                              :class="{ 'is-invalid': errors.length }"
                              v-bind="field"
                            />

                            <ErrorMessage name="city" class="invalid-feedback" />
                          </b-form-group>
                        </Field>
                      </div>

                      <div class="col-sm-4 col-lg-5 ps-sm-0">
                        <Field
                          name="post_code"
                          rules="required|min:3|max:10"
                          :label="$t('Profile.PersonalData.Postcode')"
                          v-slot="{ errors, field }"
                        >
                          <b-form-group class="mb-3 text-muted" :label="$t('Profile.PersonalData.Postcode') + '*'">
                            <b-form-input
                              v-model="form.post_code"
                              type="text"
                              :placeholder="$t('Profile.PersonalData.Postcode')"
                              :class="{ 'is-invalid': errors.length }"
                              v-bind="field"
                            />

                            <ErrorMessage name="post_code" class="invalid-feedback" />
                          </b-form-group>
                        </Field>
                      </div>
                    </div>

                    <Field
                      name="address"
                      rules="required|min:1|max:255"
                      :label="$t('Profile.PersonalData.Address')"
                      v-slot="{ errors, field }"
                    >
                      <b-form-group class="mb-3 text-muted" :label="$t('Profile.PersonalData.Address') + '*'">
                        <b-form-input
                          v-model="form.address"
                          type="text"
                          :placeholder="$t('Profile.PersonalData.Address')"
                          :class="{ 'is-invalid': errors.length }"
                          v-bind="field"
                        />

                        <ErrorMessage name="address" class="invalid-feedback" />
                      </b-form-group>
                    </Field>

                    <Field
                      name="passport"
                      rules="min:1|max:255"
                      :label="$t('Profile.PersonalData.Passport')"
                      v-slot="{ errors, field }"
                    >
                      <b-form-group class="mb-3 text-muted" :label="$t('Profile.PersonalData.Passport')">
                        <b-form-input
                          v-model="form.passport"
                          type="text"
                          :placeholder="$t('Profile.PersonalData.Passport')"
                          :class="{ 'is-invalid': errors.length }"
                          v-bind="field"
                        />

                        <ErrorMessage name="passport" class="invalid-feedback" />
                      </b-form-group>
                    </Field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </b-card-text>

      <profile-footer @save="handleSubmit($event, submit)" @cancel="resetForm" />
    </Form>
  </b-overlay>
</template>
